export default class extends ApplicationController {
  notImplementedAlert (evt) {
    if (evt) { evt.preventDefault() }
    window.Swal.fire(
      { title: window.I18n.t('frontend.common.not_implemented'), icon: 'info' }
    )
  }

  toast (title, msg, kind) {
    const IZITOAST_SETTINGS =
      {
        class: 'mt-4',
        position: 'topCenter',
        displayMode: '1',
        layout: '2',
        titleSize: '1.4em',
        messageSize: '1.1em',
        titleLineHeight: '1.4em'
      }
    const kind_values = {
      success: { icon: 'check', color: '#4dbd74' },
      error: { icon: 'fire', color: '#f86c6b' },
      alert: { icon: 'bell', color: '#ffc107' },
      notice: { icon: 'info', color: '#63c2de' }
    }
    window.IziToast.settings(IZITOAST_SETTINGS)
    window.IziToast.show({
      title: title,
      message: msg,
      icon: `icon-${kind_values[kind].icon}`,
      color: kind_values[kind].color
    })
  }
}