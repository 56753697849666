// rails and stimulus runtimes
require("@rails/ujs").start()
require("turbolinks").start()
import "controllers"

// main libraries
import JQuery from "jquery"
window.$ = window.JQuery = JQuery
import "popper.js"
import "@coreui/coreui"
// important: all utilities needed by backend generated js must be
// assigned to global window here:
import Swal from 'sweetalert2'
window.Swal = Swal
import IziToast from "izitoast/dist/js/iziToast.min.js"
window.IziToast = IziToast

// specialized libraries
require("datatables.net-bs4")
require("datatables.net-responsive-bs4")
require("datatables.net-buttons-bs4")
require("datatables.net-select-bs4")
require("jquery-ui/ui/widgets/datepicker.js")
require("jquery-ui/ui/i18n/datepicker-es.js")
require("validations")

// vendor
import "javascript"
require("i18n/frontend.js")
