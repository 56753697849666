const PERIODE = [
  {
    name: 'mes',
    code: 'month',
    months: 1,
    price: 1
  },
  {
    name: 'año',
    code: 'year',
    months: 12,
    price: 0.9
  },
  {
    name: 'dos años',
    code: 'two_years',
    months: 24,
    price: 0.8
  }
]
const LIFE_PRICE = 37.5

export default class extends ApplicationController {
  static targets = ['periodeSelectUsers', 'periodeSelect', 'lifeUserCount']

  connect () {
    this.calcPeriodePayment()
    this.calcLifePayment()
  }

  calcPeriodePayment() {
    document.getElementById("span-periode-user-count").innerHTML = this.periodeUsers
    document.getElementById("span-periode-price").innerHTML = this.formatCurr(
      this.periodeUsers * PERIODE[this.periodeIdx].price * PERIODE[this.periodeIdx].months
    )
    document.getElementById("span-periode").innerHTML = `cada ${PERIODE[this.periodeIdx].name}`
    var company = this.getCompany()
    if (company) {
      var body = `Por favor, cambien mi plan a ${this.periodeSelectUsersTarget.value} usuarios con pago ${this.periodeSelectTarget.value} por valor de ${document.getElementById('periodeFull').innerText}.`
      var subject = this.buildSubject(company)
      document.getElementById('periodePlan').setAttribute(
        'href',
        `mailto:info@sistemasc.net?body=${body}&subject=${subject}`)
    }
  }

  calcLifePayment() {
    document.getElementById("span-life-user-count").innerHTML = this.lifeUserCount
    document.getElementById("span-life-price").innerHTML = this.formatCurr(this.lifeUserCount * LIFE_PRICE)
    var company = this.getCompany()
    if (company) {
      var body = `Por favor, cambien mi plan a ${this.lifeUserCountTarget.value} usuarios por valor de ${document.getElementById('lifeFull').innerText}.`
      var subject = this.buildSubject(company)
      document.getElementById('lifePlan').setAttribute(
        'href',
        `mailto:info@sistemasc.net?body=${body}&subject=${subject}`)
    }
  }

  freePlanSignup (evt) {
    window.jQuery.get(evt.target.getAttribute('data-url'))
  }

  periodeSignup (evt) {
    var periode = PERIODE[this.periodeIdx].code
    window.jQuery.get(evt.target.getAttribute('data-url') + `?paid_plan=${periode}&users=${this.periodeUsers}&periode=${this.periodeIdx}`)
  }

  lifeSignup (evt) {
    window.jQuery.get(evt.target.getAttribute('data-url') + `?paid_plan=life&users=${this.lifeUserCount}`)
  }

  get periodeIdx () {
    return this.periodeSelectTarget.selectedIndex
  }

  get periodeUsers () {
    return parseInt(this.periodeSelectUsersTarget.value)
  }

  get lifeUserCount () {
    return parseInt(this.lifeUserCountTarget.value)
  }

  formatCurr (value) {
    return `${value}€`
  }

  getCompany() {
    var name = document.getElementById('company_name').getAttribute('data-name')
    if (name) {
      return {
        name: name,
        nick: document.getElementById('company_name').getAttribute('data-nick')
      }
    }
  }

  buildSubject(company) {
    return `Cambiar suscripción Clockin.es. ${company.name} (${company.nick})`
  }

}