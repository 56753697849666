// this controller is attached at layout level, every page loads it
import CommonController from "controllers/shared/common_controller"
import PunchesIndexController from "controllers/punches/index_controller"
import "bootstrap/js/dist/tooltip"

export default class extends ApplicationController {
  connect () {
    // console.log('DEBUG. initialized loader controller')

    if (!window.App) { window.App = {} }
    window.App.common_controller = new CommonController()
    window.App.punches_index_controller = new PunchesIndexController()

    this.focusInvalidFields()
    // enable tooltips everywhere
    $('[data-toggle="tooltip"]').tooltip()

    window.setTimeout(
      () => $('.alert-dimissable').fadeTo(500, 0).slideUp(500),
      4000)

    // exit from modals with ESC
    $(document).on('keydown', (evt) => {
        evt = evt || window.event
        if (evt.keyCode === 27) {
          try {
            evt.preventDefault()
          } catch (x) {
            evt.returnValue = false
          }
          $('.modal.fade.show').modal('hide')
          $('body').removeClass('modal-open')
          return $('.modal-backdrop').remove()
        }
      })

  }

  takeFocus (selector) {
    if ($(selector).length > 0) {
      $(selector)[0].focus()
      $(selector)[0].select()
    }
  }

  focusInvalidFields () {
    if (['new', 'create'].includes(this.action())) {
      if ($('input.is-invalid').length > 0) {
        $('input.is-invalid').first().trigger('focus')
      } else {
        this.takeFocus('.action_new_autofocus')
      }
    } else {
      if (['edit', 'update'].includes(this.action())) {
        if ($('input.is-invalid').length > 0) {
          $('input.is-invalid').first().trigger('focus')
        } else {
          this.takeFocus('.action_edit_autofocus')
        }
      }
    }
  }

}
