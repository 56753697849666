/* globals moment */
import Swal from "sweetalert2"
export default class extends ApplicationController {
  static targets = ['userSelector', 'controller']

  connect () {
    if (!window.weeks) {
      return
    }
    this.calcAllTotals()
    setInterval(() => { this.calcAllTotals() }, 60000)
    setInterval(() => { this.refreshPage() }, 600000)
    $('tr').on('click', (evt) => {
      $('tr').removeClass('table-active')
      $(evt.target).parent().addClass('table-active')
    })
  }

  setUser () {
    this.currentUser = this.userSelectorTarget.value
    this.updateIndex()
  }

  setDate (evt) {
    evt.preventDefault()
    this.currentDirection = evt.target.getAttribute('data-direction')
    this.updateIndex()
  }

  print (evt) {
    evt.preventDefault()
    let self = this
    Swal.fire({
      title: I18n.t('frontend.report_dialog.title'),
      text: I18n.t('frontend.report_dialog.text'),
      icon: 'question',
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonText: I18n.t('frontend.report_dialog.month'),
      denyButtonText: I18n.t('frontend.report_dialog.year'),
      reverseButtons: false,
      didOpen: () => {
        const yearBtnStyle = document.querySelector('.swal2-deny').style
        const monthBtnStyle = getComputedStyle(document.querySelector('.swal2-confirm'))
        yearBtnStyle.width = monthBtnStyle.width
        yearBtnStyle.backgroundColor = monthBtnStyle.backgroundColor
      }
    }).then((result) => {
      if (result.isConfirmed) {
        self.report('month')
      } else if(result.isDenied) {
        self.report('year')
      }
    })
  }

  report (report_type)  {
    var context = this.getContext()
    window
      .location
      .replace(
        `${this.controllerTarget.getAttribute('data-print-url')}`+
        `?user=${context.user}`+
        `&date=${context.date}`+
        `&direction=${context.direction}`+
        `&report=${report_type}`
      )
  }

  getContext () {
    var user
    if (this.hasUserSelectorTarget) {
      user = this.currentUser || this.userSelectorTarget.value
    } else {
      user = this.controllerTarget.getAttribute('data-user')
    }
    var date = this.currentDate || this.controllerTarget.getAttribute('data-date')
    var direction = this.currentDirection || 0
    return {
      "user": user,
      "date": date,
      "direction": direction
    }
  }

  updateIndex () {
    var context = this.getContext()
    window
      .location
      .replace(
        `${this.controllerTarget.getAttribute('data-index-url')}?user=${context.user}&date=${context.date}&direction=${context.direction}`
      )
  }

  calcAllTotals () {
    let month = {
      morning: '0:00',
      afternoon: '0:00',
      extra: '0:00',
      total: '0:00',
      work: 0,
      holiday: 0,
      sick_leave: 0
    }
    this.calcRunning()

    for (const week of window.weeks) {
      this.calcWeek(week, month)
    }
    this.buildStatusTotal('#month_status_total', month)
    $('#total_month').text(month.total)
  }

  calcRunning () {
    const tr = $('tr.today')
    if (tr.length != 1) return

    let dayDuration = '0:00'
    for (const partial of ['morning', 'afternoon', 'extra']) {
      const duration = tr.find(`td.${partial}_duration`)
      const start = tr.find(`td.${partial}_begin`).text()
      if (start > '') {
        let finish = tr.find(`td.${partial}_end`).text()
        if ((finish == '') && (tr.hasClass('today'))) {
          finish = moment().format('H:mm')
        }
        const total = this.minusTime(
          this.strToHourMinute(finish),
          this.strToHourMinute(start)
        )
        dayDuration = this.plusTime(
          this.strToHourMinute(dayDuration),
          this.strToHourMinute(total)
        )
        duration.text(this.addZeros(total))
      }
    }
    let tdDayDuration = tr.find('td.day_duration')
    tdDayDuration.text(this.addZeros(dayDuration))
  }

  calcWeek (week, month) {
    $(`#week${week}_week_status_total`).html('')
    var work = $(`#week${week}_table`).find('.icon-energy').length
    month.work += work
    var holiday = $(`#week${week}_table`).find('.icon-plane').length
    month.holiday += holiday
    var sick_leave = $(`#week${week}_table`).find('.icon-cup').length
    month.sick_leave += sick_leave
    var total_days = '0:00'
    for (const partial of ['morning', 'afternoon', 'extra']) {
      var items = $(`.week${week}_${partial}_duration`)
      var total_item = '0:00'
      for (const item of items) {
        total_item = this.plusPeriod(total_item, $(item).text())
      }
      $(`#week${week}_${partial}s_total`).text(total_item)
      month[partial] = this.plusPeriod(month[partial], total_item)
      total_days = this.plusPeriod(total_days, total_item)
    }
    $(`#week${week}_days_total`).text(total_days)
    this.buildStatusTotal(`#week${week}_week_status_total`, {
      work: work,
      holiday: holiday,
      sick_leave: sick_leave
    })
    month.total = this.plusPeriod(month.total, total_days)
    $('#total_month_partials').text(`${month.morning} / ${month.afternoon} / ${month.extra}`)
  }

  buildStatusTotal(selector, total) {
    var result = ''
    if (total.work > 0) {
      result += `<i class="icon-energy"/><span class="mr-2">${total.work}</span>`
    }
    if (total.holiday > 0) {
      result += `<i class="icon-plane"/><span class="mr-2">${total.holiday}</span>`
    }
    if (total.sick_leave > 0) {
      result += `<i class="icon-cup"/><span class="mr-2">${total.sick_leave}</span>`
    }
    $(selector).html(result)
  }
  // Suma cada periodo al total i devuelve horas totales como String "h:mm".
  plusPeriod (total, period) {
    if (total.indexOf('-') === -1) {
      total = this.strToHourMinute(total)
      if (period.indexOf('-') === -1) {
        period = this.strToHourMinute(period)
        return this.addZeros(this.plusTime(total, period))
      } else {
        period = this.strToHourMinute(period.replace('-', ''))
        if (this.isHigerTime(total, period)) {
          return this.addZeros(this.minusTime(total, period))
        } else {
          return this.addZeros('-' + this.minusTime(period, total))
        }
      }
    } else {
      total = this.strToHourMinute(total.replace('-', ''))
      if (period.indexOf('-') === -1) {
        period = this.strToHourMinute(period)
        if (this.isHigerTime(total, period)) {
          return this.addZeros('-' + this.minusTime(total, period))
        } else {
          return this.addZeros(this.minusTime(period, total))
        }
      } else {
        period = this.strToHourMinute(period.replace('-', ''))
        return this.addZeros('-' + this.plusTime(total, period))
      }
    }
  }

  strToHourMinute (value) {
    var result = value.split(':').map(Number)
    if (result.length == 2) {
      return result
    } else {
      return [0,0]
    }
  }

  // Devuelve bool, si el primer parametro es mayor al segundo.
  isHigerTime (total, period) {
    var num1 = total.slice()
    var num2 = period.slice()
    if (num1[0] > num2[0]) {
      return true
    } else if (num1[0] < num2[0]) {
      return false
    } else {
      if (num1[1] >= num2[1]) return true
      else return false
    }
  }

  // suma tiempos, recibe Arrays con Integers en formato [h, m], devuelve String en formato "h:m".
  plusTime (time1, time2) {
    var resTime1 = time1[0] * 60 + time1[1]
    var resTime2 = time2[0] * 60 + time2[1]
    var res = resTime1 + resTime2
    return Math.floor(res / 60) + ':' + res % 60
  }

  // resta tiempos, recibe Arrays con Integers en formato [h, m], devuelve String en formato "h:m".
  minusTime (time1, time2) {
    var resTime1 = time1[0] * 60 + time1[1]
    var resTime2 = time2[0] * 60 + time2[1]
    var res = resTime1 - resTime2
    if (res < 0) {
      return '0:00'
    } else {
      return Math.floor(res / 60) + ':' + res % 60
    }
  }

  // añade ceros a minutos, recibe String en formato "h:m", devuelve String en formato "h:mm".
  addZeros (time) {
    time = time.split(':')
    if (time[1].length < 2) {
      time[1] = '0' + time[1]
    }
    return time.join(':')
  }

  refreshPage () {
    window.location = '/'
    window.location.reload(true)
  }
}
