// put here legacy js files and their dependencies

import "./bootstrap43"

import I18n from "./i18n"
window.I18n = I18n

import "./sweetalert.js"

import "moment/locale/es"
import moment from "moment/min/moment.min"
moment.locale('es')
window.moment = moment
require("./tempusdominus-bootstrap-4.js")
require("daterangepicker/daterangepicker")

